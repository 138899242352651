import axios from "axios";
import { toDisplayString } from "vue";
import { createStore } from "vuex";
import validation from "../assets/validation";
export default createStore({
    state: {
        board: {
            initState: [
                [0, 0, 0, 0, 0, 0, 0, 0, 0],
                [0, 0, 0, 0, 0, 0, 0, 0, 0],
                [0, 0, 0, 0, 0, 0, 0, 0, 0],
                [0, 0, 0, 0, 0, 0, 0, 0, 0],
                [0, 0, 0, 0, 0, 0, 0, 0, 0],
                [0, 0, 0, 0, 0, 0, 0, 0, 0],
                [0, 0, 0, 0, 0, 0, 0, 0, 0],
                [0, 0, 0, 0, 0, 0, 0, 0, 0],
                [0, 0, 0, 0, 0, 0, 0, 0, 0],
            ],
            currentState: [
                [0, 0, 0, 0, 0, 0, 0, 0, 0],
                [0, 0, 0, 0, 0, 0, 0, 0, 0],
                [0, 0, 0, 0, 0, 0, 0, 0, 0],
                [0, 0, 0, 0, 0, 0, 0, 0, 0],
                [0, 0, 0, 0, 0, 0, 0, 0, 0],
                [0, 0, 0, 0, 0, 0, 0, 0, 0],
                [0, 0, 0, 0, 0, 0, 0, 0, 0],
                [0, 0, 0, 0, 0, 0, 0, 0, 0],
                [0, 0, 0, 0, 0, 0, 0, 0, 0],
            ],
            solvedState: [],

            helpState: [],
        },
        game: {
            difficulties: [
                { id: 0, level: "easy", name: "Einfach" },
                { id: 1, level: "medium", name: "Mittel" },
                { id: 2, level: "hard", name: "Schwer" },
            ],
            currentDifficulty: "easy",
            name: "",
            stopwatch: {},
            hasStarted: false,
            isPaused: false,
            solved: false,
            finishedTime: "",
        },
        settings: {
            openSettings: false,
        },
    },
    getters: {},
    mutations: {
        setInitBoard(state, arr) {
            state.board.initState = arr;
        },
        setCurrentBoard(state, arr) {
            state.board.currentState = arr;
        },
        setHelpBoard(state, arr) {
            state.board.helpState = arr;
        },
        changeCellInCurrentBoard(state, payload) {
            state.board.currentState[payload.x][payload.y] = payload.value;
        },
        toggleCellInHelpBoard(state, payload) {
            state.board.helpState[payload.x][payload.y][payload.value] =
                !state.board.helpState[payload.x][payload.y][payload.value];
        },
        flipStatesInHelpBoard(state, payload) {
            for (let i = 0; i < 9; i++) {
                state.board.helpState[payload.x][payload.y][i] =
                    !state.board.helpState[payload.x][payload.y][i];
            }
        },

        setStopwatch(state, obj) {
            state.game.stopwatch = obj;
        },
        toggleSettings(state, toggle) {
            state.settings.openSettings = toggle;
        },
        setCurrentDifficulty(state, difficulty) {
            state.game.currentDifficulty = difficulty;
        },

        toggleHasStarted(state, toggle) {
            state.game.hasStarted = toggle;
        },

        toggleIsPaused(state, toggle) {
            state.game.isPaused = toggle;
        },

        setSolvedBoard(state, arr) {
            state.board.solvedState = arr;
        },

        setSolved(state, solved) {
            state.game.solved = solved;
        },
        setFinishedTime(state, time) {
            state.game.finishedTime = time;
        },
    },
    actions: {
        changeCell({ commit, state }, payload) {
            commit("changeCellInCurrentBoard", payload);
            this.dispatch("validate").then(() => {
                if (this.state.game.solved) {
                    state.game.stopwatch.pause();
                    commit(
                        "setFinishedTime",
                        state.game.stopwatch.hours.toLocaleString("en-US", {
                            minimumIntegerDigits: 2,
                        }) +
                            " : " +
                            state.game.stopwatch.minutes.toLocaleString(
                                "en-US",
                                {
                                    minimumIntegerDigits: 2,
                                }
                            ) +
                            " : " +
                            state.game.stopwatch.seconds.toLocaleString(
                                "en-US",
                                {
                                    minimumIntegerDigits: 2,
                                }
                            )
                    );
                }
            });
        },

        init({ commit, dispatch }) {
            var globalBoard = [];
            var difficulty = this.state.game.currentDifficulty;
            axios.get("/board?difficulty=" + difficulty).then((res) => {
                globalBoard = res.data.board;

                commit("setInitBoard", JSON.parse(JSON.stringify(globalBoard))); // JSON.parse(JSON.stringify(arr)) copy arr not reference it!
                commit(
                    "setCurrentBoard",
                    JSON.parse(JSON.stringify(globalBoard))
                );
                //dispatch("validate");
            });
        },
        reset({ commit }) {
            const initBoard = [
                [0, 0, 0, 0, 0, 0, 0, 0, 0],
                [0, 0, 0, 0, 0, 0, 0, 0, 0],
                [0, 0, 0, 0, 0, 0, 0, 0, 0],
                [0, 0, 0, 0, 0, 0, 0, 0, 0],
                [0, 0, 0, 0, 0, 0, 0, 0, 0],
                [0, 0, 0, 0, 0, 0, 0, 0, 0],
                [0, 0, 0, 0, 0, 0, 0, 0, 0],
                [0, 0, 0, 0, 0, 0, 0, 0, 0],
                [0, 0, 0, 0, 0, 0, 0, 0, 0],
            ];
            commit("toggleHasStarted", false);
            commit("toggleIsPaused", false);
            commit("setInitBoard", [
                [0, 0, 0, 0, 0, 0, 0, 0, 0],
                [0, 0, 0, 0, 0, 0, 0, 0, 0],
                [0, 0, 0, 0, 0, 0, 0, 0, 0],
                [0, 0, 0, 0, 0, 0, 0, 0, 0],
                [0, 0, 0, 0, 0, 0, 0, 0, 0],
                [0, 0, 0, 0, 0, 0, 0, 0, 0],
                [0, 0, 0, 0, 0, 0, 0, 0, 0],
                [0, 0, 0, 0, 0, 0, 0, 0, 0],
                [0, 0, 0, 0, 0, 0, 0, 0, 0],
            ]);
            commit("setCurrentBoard", [
                [0, 0, 0, 0, 0, 0, 0, 0, 0],
                [0, 0, 0, 0, 0, 0, 0, 0, 0],
                [0, 0, 0, 0, 0, 0, 0, 0, 0],
                [0, 0, 0, 0, 0, 0, 0, 0, 0],
                [0, 0, 0, 0, 0, 0, 0, 0, 0],
                [0, 0, 0, 0, 0, 0, 0, 0, 0],
                [0, 0, 0, 0, 0, 0, 0, 0, 0],
                [0, 0, 0, 0, 0, 0, 0, 0, 0],
                [0, 0, 0, 0, 0, 0, 0, 0, 0],
            ]);
            commit("setSolvedBoard", []);

            let helpBoard = Array.from(Array(9), () => new Array(9));
            for (let i = 0; i < 9; i++) {
                for (let j = 0; j < 9; j++) {
                    helpBoard[i][j] = new Array(9).fill(false);
                }
            }

            commit("setHelpBoard", helpBoard);
            commit("setSolved", false);
        },

        validate({ commit }) {
            commit(
                "setSolved",
                validation.validate(this.state.board.currentState)
            );
        },
    },
    modules: {},
});
