let validRow = (board, row) => board[row].length == new Set(board[row]).size;

let validColumn = (board, col) => {
    let column = board.map((x) => x[col]);
    return column.length == new Set(column).size;
};

let validBox = (board, row, col) => {
    let arr = [];
    for (let x = row; x < row + 3; x++) {
        for (let y = col; y < col + 3; y++) {
            arr.push(board[x][y]);
        }
    }
    return arr.length == new Set(arr).size;
};

let validate = (board) => {
    for (let i = 0; i < 9; i++) {
        // check if board still contains zeros
        for (let j = 0; j < 9; j++) {
            if (board[i][j] == 0) {
                return false;
            }
        }
    }

    for (let i = 0; i < 9; i++) {
        // check all rows and column for unique numbers
        if (!(validRow(board, i) && validColumn(board, i))) {
            return false;
        }
    }

    for (let x = 0; x < 9; x = x + 3) {
        // check all boxes for unique numbers
        for (let y = 0; y < 9; y = y + 3) {
            if (!validBox(board, x, y)) {
                return false;
            }
        }
    }

    return true;
};

export default { validate };
