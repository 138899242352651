<template>
    <div class="main" style="user-select: none" @keydown="avoidTab($event)">
        <p class="title">Sudoku</p>

        <div
            @click="toggleSettings"
            class="icon nav-icon-4"
            :class="{ icon_hide: isSettingsOpen }"
            data-bs-toggle="offcanvas"
            href="#offcanvasSettings"
            role="button"
            aria-controls="offcanvasSettings"
        >
            <span></span>
            <span></span>
            <span></span>
        </div>
        <div
            class="offcanvas offcanvas-start settings"
            data-bs-backdrop="false"
            tabindex="-1"
            id="offcanvasSettings"
            aria-labelledby="offcanvasSettingsLabel"
        >
            <div class="offcanvas-header">
                <h2 class="offcanvas-title" id="offcanvasSettingsLabel">
                    Settings
                </h2>
                <button
                    @click="toggleSettings"
                    type="button"
                    class="btn-close btn-lg text-reset"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                ></button>
            </div>
            <div class="offcanvas-body">
                <div class="difficulty">
                    <label for="difficulty">Schwierigkeitsgrad</label>
                    <select
                        name="difficulty"
                        id="select-difficulty"
                        @change="changeDifficulty($event)"
                    >
                        <option v-for="item in getDifficulties" :key="item.id">
                            {{ item.name }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div
            class="input"
            :class="{ deselect: !getHasStarted || getIsPaused }"
            @contextmenu.prevent="onInput($event)"
        >
            <div class="wrapper">
                <div v-for="i in 3" :key="i" class="my-row">
                    <div
                        v-for="j in 3"
                        :key="j"
                        class="cell"
                        @click="
                            clickHelpCell(
                                getCurrentX,
                                getCurrentY,
                                j - 1 + 3 * i - 3
                            )
                        "
                    >
                        <span
                            :class="{
                                deselect:
                                    !getHelpBoard[getCurrentX][getCurrentY][
                                        j - 1 + 3 * i - 3
                                    ],
                            }"
                            >{{ j + 3 * i - 3 }}</span
                        >
                    </div>
                </div>
            </div>
        </div>
        <div class="board">
            <div v-for="i in 9" :key="i" class="my-row">
                <div
                    v-for="j in 9"
                    :key="j"
                    :id="'cell_' + i + '-' + j"
                    class="cell"
                    :class="{ immutable: getInitBoard[i - 1][j - 1] != 0 }"
                    tabindex="0"
                    @click="clickOnCell(i - 1, j - 1, $event)"
                    @keydown="keyOnCell(i - 1, j - 1, $event)"
                >
                    <span :class="{ hide_cell: getBoard[i - 1][j - 1] == 0 }">{{
                        getBoard[i - 1][j - 1]
                    }}</span>
                </div>
            </div>
        </div>
        <div class="winner" :class="{ hide_cell: !getSolved }">
            <h1>Gelöst!</h1>
            <h2>
                Reset <i class="im im-reset"></i> und Play <i class="im im-play"></i> drücken um nochmal zu spielen
            </h2>
        </div>
        <div class="footer">
            <div>
                <div class="time">
                    <span>{{
                        getStopwatch.hours.toLocaleString("en-US", {
                            minimumIntegerDigits: 2,
                        })
                    }}</span>
                    :
                    <span>{{
                        getStopwatch.minutes.toLocaleString("en-US", {
                            minimumIntegerDigits: 2,
                        })
                    }}</span>
                    :
                    <span>{{
                        getStopwatch.seconds.toLocaleString("en-US", {
                            minimumIntegerDigits: 2,
                        })
                    }}</span>
                </div>
                <button class="my-btn btn-start" @click="pressContinue()">
                    <i class="im im-play"></i>
                </button>
                <button class="my-btn btn-pause" @click="pressPause()">
                    <i class="im im-pause"></i>
                </button>
                <button class="my-btn btn-reset" @click="pressReset()">
                    <i class="im im-reset"></i>
                </button>
            </div>
        </div>
    </div>
</template>
<script>
import { useStopwatch } from "vue-timer-hook";
import { useStore } from "vuex";
export default {
    name: "App",
    data() {
        return {
            currentX: 0,
            currentY: 0,
        };
    },
    setup() {
        const store = useStore();

        const autostart = false;
        const stopwatch = useStopwatch(autostart);
        store.commit("setStopwatch", stopwatch);
        stopwatch.pause(); // autostart doesnt work

        let helpBoard = Array.from(Array(9), () => new Array(9));
        for (let i = 0; i < 9; i++) {
            for (let j = 0; j < 9; j++) {
                helpBoard[i][j] = new Array(9).fill(false);
            }
        }

        store.commit("setHelpBoard", helpBoard);

        return {
            stopwatch,
        };
    },
    computed: {
        getIsPaused() {
            return this.$store.state.game.isPaused;
        },
        getHasStarted() {
            return this.$store.state.game.hasStarted;
        },
        getCurrentX() {
            return this.currentX;
        },
        getCurrentY() {
            return this.currentY;
        },
        getHelpBoard() {
            return this.$store.state.board.helpState;
        },
        getBoard() {
            return this.$store.state.board.currentState;
        },
        getInitBoard() {
            return this.$store.state.board.initState;
        },
        getStopwatch() {
            return this.$store.state.game.stopwatch;
        },
        isSettingsOpen() {
            return this.$store.state.settings.openSettings;
        },
        getDifficulties() {
            return this.$store.state.game.difficulties;
        },
        getSolved() {
            return this.$store.state.game.solved;
        },
    },
    methods: {
        onInput(event) {
            let payload = {
                x: this.getCurrentX,
                y: this.getCurrentY,
            };
            this.$store.commit("flipStatesInHelpBoard", payload);
        },
        avoidTab(event) {
            if (event.code == "Tab") {
                event.preventDefault();
            }
        },
        clickHelpCell(x, y, value) {
            if (true) {
                let payload = {
                    x: x,
                    y: y,
                    value: value,
                };
                this.$store.commit("toggleCellInHelpBoard", payload);
            }
        },

        clickOnCell(x, y, event) {
            this.currentX = x;
            this.currentY = y;
            let index = event.path.findIndex((item) => item.tagName == "DIV");
            let target = event.path[index];
            let rows = Object.values(event.path[index + 2].children);

            rows.forEach((row) => {
                Object.values(row.children).forEach((cell) => {
                    //clear all cell borders
                    cell.style = "border: none;";
                });
            });

            target.style = "border: 2px solid rgba(255, 255, 255, 0.5);";
        },

        keyOnCell(x, y, event) {
            if (/^\d+$/.test(event.key) || event.code == "Backspace") {
                //valid input
                if (
                    !this.$store.state.game.isPaused &&
                    this.$store.state.game.hasStarted
                ) {
                    // valid input and game is running change number in cell
                    let payload = {
                        x: x,
                        y: y,
                        value:
                            event.code == "Backspace" ? 0 : parseInt(event.key),
                    };
                    // this.$store.commit("changeCellInCurrentBoard", payload);
                    this.$store.dispatch("changeCell", payload);
                }
                
            }
        },

        toggleSettings() {
            var currentState = this.$store.state.settings.openSettings;
            this.$store.commit("toggleSettings", !currentState);
        },

        changeDifficulty(event) {
            var name = event.target.value;
            var difficulty = this.$store.state.game.difficulties.filter(
                (obj) => obj.name === name
            )[0].level;
            this.$store.commit("setCurrentDifficulty", difficulty);
        },

        pressContinue() {
            const hasStarted = this.$store.state.game.hasStarted;
            if (!hasStarted) {
                this.$store.dispatch("init"); //init start game
                this.getStopwatch.start();

                let cell =
                    "cell_" +
                    (this.getCurrentX + 1) +
                    "-" +
                    (this.getCurrentY + 1); // ugly
                cell = document.getElementById(cell); // af
                cell.style = "border: 2px solid rgba(255, 255, 255, 0.5);"; // :o

                this.$store.commit("toggleHasStarted", !hasStarted);
            } else if (this.$store.state.game.isPaused) {
                this.getStopwatch.start();
                this.$store.commit("toggleIsPaused", false);
            }
        },

        pressPause() {
            this.getStopwatch.pause();
            this.$store.commit("toggleIsPaused", true);
        },

        pressReset() {
            this.getStopwatch.reset();
            this.getStopwatch.pause();
            //this.currentX = this.currentY = 0;
            this.$store.dispatch("reset");
        },
    },
};
</script>
<style>
#select-difficulty {
    width: 250px;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    border: none;
    height: 40px;
    padding-left: 10px;
    font-family: "Maven Pro" sans-serif;
    font-size: 16px;
    margin-top: 10px;
    border-radius: 5px;
}
.difficulty {
    border-top: black solid 2px;
    border-bottom: black solid 2px;
    width: 100%;
    padding-bottom: 15px;
}
.difficulty label {
    padding: 0;
    margin: 0;
    font-family: "Maven Pro" sans-serif;
    font-size: 32px;
}
/* nav-icon-4 */
.nav-icon-4 {
    width: 35px;
    height: 30px;
    margin: 10px 10px;
    position: relative;
    cursor: pointer;
    display: inline-block;
}
.nav-icon-4 span {
    background-color: #fff;
    position: absolute;
    border-radius: 2px;
    transition: 0.3s cubic-bezier(0.8, 0.5, 0.2, 1.4);
}
.nav-icon-4 span:nth-child(1) {
    width: 100%;
    height: 4px;
    display: block;
    top: 0px;
    left: 0px;
}
.nav-icon-4 span:nth-child(2) {
    width: 100%;
    height: 4px;
    display: block;
    top: 13px;
    left: 0px;
}
.nav-icon-4 span:nth-child(3) {
    width: 100%;
    height: 4px;
    display: block;
    bottom: 0px;
    left: 0px;
}
.nav-icon-4:not(.open):hover span:nth-child(1) {
    width: 100%;
    height: 4px;
    display: block;
    top: -2px;
    left: 0px;
    transition: 0.3s cubic-bezier(0.8, 0.5, 0.2, 1.4);
}
.nav-icon-4:not(.open):hover span:nth-child(2) {
    width: 100%;
    height: 4px;
    display: block;
    top: 13px;
    left: 0px;
    transition: 0.4s cubic-bezier(0.8, 0.5, 0.2, 1.4);
}
.nav-icon-4:not(.open):hover span:nth-child(3) {
    width: 100%;
    height: 4px;
    display: block;
    bottom: -2px;
    left: 0px;
    transition: 0.3s cubic-bezier(0.8, 0.5, 0.2, 1.4);
}
.nav-icon-4.open {
    transform: rotate(90deg);
}
.nav-icon-4.open span:nth-child(1) {
    left: 3px;
    top: 12px;
    width: 30px;
    transition: 0.3s cubic-bezier(0.8, 0.5, 0.2, 1.4);
    transform: rotate(90deg);
    transition-delay: 150ms;
}
.nav-icon-4.open span:nth-child(2) {
    left: 2px;
    top: 20px;
    width: 20px;
    transition: 0.3s cubic-bezier(0.8, 0.5, 0.2, 1.4);
    transform: rotate(45deg);
    transition-delay: 50ms;
}
.nav-icon-4.open span:nth-child(3) {
    left: 14px;
    top: 20px;
    width: 20px;
    transition: 0.3s cubic-bezier(0.8, 0.5, 0.2, 1.4);
    transform: rotate(-45deg);
    transition-delay: 100ms;
}

@import url("https://fonts.googleapis.com/css2?family=Maven+Pro:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400&display=swap");
#app {
    font-family: "Maven Pro" sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #172431;
}
.icon {
    position: absolute;
    top: 0;
    left: 0;
    margin: 30px;
    transform: scale(1.5);
}
.icon_hide {
    display: none;
}
.settings {
    background-color: rgba(255, 255, 255, 0.637) !important;
    box-shadow: 7px 9px 28px 9px rgba(0, 0, 0, 0.6);
}

.input {
    position: absolute;
    top: 0;
    right: 0;
    width: 25vw;

    height: 400px;
    background-color: rgba(255, 255, 255, 0.637);
    box-shadow: -7px 9px 28px 9px rgba(0, 0, 0, 0.6);
}
.input .wrapper {
    padding-top: 35px;
}

.input .wrapper .cell {
    width: 100px;
    height: 100px;
    margin: 5px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0;
    font-size: 60px;
    font-family: "Maven Pro", sans-serif;
    font-weight: 600;
    user-select: none;
    background: rgba(255, 255, 255, 0.212);
}

.input .wrapper .cell:hover {
    background-color: rgba(255, 255, 255, 0.112);
}
.deselect {
    visibility: hidden;
}
.footer {
    width: 100%;
    position: absolute;
    bottom: 2%;
}
.winner {
    /* height: calc(0.5 * 50vh);
    min-height: 300px;
    margin-top: calc(50vh - 300px);
    background: rgb(255, 255, 255); */
    position: absolute;
    top: 20vh;
    left: 0;
    right: 0;
    max-height: 350px;
    min-height: 300px;

    background-color: rgba(255, 255, 255, 0.753);
}
.winner h1 {
    font-size: 70px;
    color: black;
    padding-bottom: 30px;
    padding-top: 10px;
    z-index: 10;
}
.winner h2 {
    color: black;
}

.time {
    font-size: 32px;
    font-family: "Maven Pro", sans-serif;
    font-weight: 700;
    color: rgb(255, 255, 255);
    padding-top: 20px;
    padding-bottom: 20px;
    text-shadow: 9px 3px 23px rgba(0, 0, 0, 0.38);
}
.my-btn {
    background: rgba(255, 255, 255, 0.637);
    border-style: none;
    margin: 2px;
    box-shadow: 9px 3px 23px 4px rgba(0, 0, 0, 0.38);
}
.my-btn i {
    padding: 20px;
}
.btn-start {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 5px;
}
.btn-reset {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 5px;
}
.main {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    background: rgb(112, 46, 173);
    background: radial-gradient(
        circle,
        rgba(112, 46, 173, 1) 0%,
        rgba(66, 118, 198, 1) 100%
    );
}

.title {
    margin: 0;
    color: rgba(255, 255, 255, 0.637);
    font-family: "Maven Pro", sans-serif;
    font-weight: 700;
    font-size: 70px;
    text-shadow: 20px;
    text-shadow: 9px 3px 23px rgb(0, 0, 0);
}
.board {
    /* position: absolute;
    top: calc(50% - 300px);

    left: calc(50% - 300px); */
    margin-top: 5vh;
}
.board .cell {
    width: 60px;
    height: 60px;
    margin: 2px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0;
    font-size: 30px;
    font-family: "Maven Pro", sans-serif;
    font-weight: 600;
    user-select: none;
    background: rgba(255, 255, 255, 0.212);
}

.cell:hover,
.cell span:hover {
    cursor: pointer;
}
.immutable {
    pointer-events: none;
    color: rgb(34, 60, 99);
}

.cell:hover {
    background-color: rgba(255, 255, 255, 0.112);
}

.cell:focus {
    border: 2px solid rgba(255, 255, 255, 0.5);
}

.hide_cell {
    visibility: hidden;
}

.my-row:nth-child(4) {
    margin-top: 7px;
}
.my-row:nth-child(7) {
    margin-top: 7px;
}
.cell:nth-child(3) {
    margin-right: 7px;
}

.cell:nth-child(6) {
    margin-right: 7px;
}
</style>
